import React from "react";
import RichText from "components/RichText";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import Layout from "components/Layout";
import SEO from "components/SEO";
import Hero from "components/Hero";

const ThanksInner = styled(Inner)`
  overflow: hidden;
`;

const ThanksContent = styled.div`
  padding: 6.4rem 0;
  max-width: 84.8rem;

  h3 {
    font-weight: 400;
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 10.4rem 0 10.4rem 11.2rem;
  }
}
`

const RenderBody = ({ contact }) => (
  <>
    <Hero
      title={contact.introduction_heading}
      variant="condensed"
    />

    <ThanksInner>
      <ThanksContent>
        <h3>Thank you for reaching out! Someone will be in touch soon.</h3>
      </ThanksContent>
    </ThanksInner>
  </>
);

const ThankYou = ({ data }) => {
  const contact = data.prismic.allContacts.edges[0].node;

  if (!contact) return null;

  return (
    <Layout headerStyle="dark">
      <SEO
        title={"Thank You"}
        description={contact.social_description ? contact.social_description : null}
        image={contact.social_image ? contact.social_image.url : null}
      />

      <RenderBody contact={contact} />
    </Layout>
  );
};

export default ThankYou;

export const query = graphql`
  {
    prismic {
      allContacts {
        edges {
          node {
            introduction_heading
            contact_info
            form_question
            embed
            form_content
            buttons {
              button_text
            }
            social_title
            social_description
            social_image
          }
        }
      }
    }
  }
`;
